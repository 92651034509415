'use strict'

###*
 # @ngdoc service
 # @name mundoAudit.factory:AuditLogs

 # @description

###
angular
  .module 'mundoAudit'
  .factory 'AuditLogs', [
    'Restangular'
    (Restangular) ->
      AuditLogsBase = Restangular
        .service('audit_logs')

      AuditLogsBase
  ]
